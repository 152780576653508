import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import Spacing from '../../components/partials/Spacing'

import { StaticImage } from 'gatsby-plugin-image'

const kafkaDataPlatformChecklist = () => {
  const data = useStaticQuery(graphql`
    query KafkaDataPlatformChecklistQuery {
      allContentfulPages(
        filter: {
          pageReference: {
            pageIdentifier: { eq: "Checklist_Kafka_Data_Platform " }
          }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      {/* <img className="home-fixed-top" src={hero_lens} alt="Hero Lenses" /> */}
      <section className="hero-margin container-1 mobile-margin-top  overflow-hidden d-block">
        <div className="d-flex flex-wrap justify-content-center">
          <p className="h4-like pb-0">Checklist</p>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          <h1 className="secondary-text">
            Building an Event Streaming Platform for Apache Kafka
          </h1>
        </div>
      </section>
      <Spacing />

      <section>
        <div className="container">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-6 col-sm-12">
              <p className="p-text">
                Requirements for your streaming data project can quickly
                snowball as you scale across your organization.
              </p>
              <p className="p-text">
                We’ve compiled a checklist of considerations when it comes to
                designing a real-time data platform, that help you achieve:
              </p>
              <ul className="red-checked-list">
                <li>
                  <span className="p-text">
                    <p>Productive people & processes</p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>Self-service access to data</p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>Standardized real-time apps</p>
                  </span>
                </li>
              </ul>
              <div className="roll-button mt-2">
                <a
                  href="/resources/DataOps_Checklist.pdf"
                  className="bg-light-blue text-white mt-1 mr-2"
                >
                  <span data-hover="Open now">Open now</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <a href="/resources/DataOps_Checklist.pdf">
                <StaticImage
                  className="w-100"
                  placeholder="tracedSVG"
                  src="../../resources/images/resources/checklist.png"
                  alt="Data Platform Checklist"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}

export default kafkaDataPlatformChecklist
